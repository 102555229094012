import React from "react";
import { BiMaleFemale } from "react-icons/bi";
import { FaRoute } from "react-icons/fa";

const LocationSvg = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="20" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        {/* <title>Layer 1</title> */}
        <path id="svg_1" fill={svgColor} d="m10,0c-5.53061,0 -10,4.32465 -10,9.67615c0,1.56005 0.42857,3.08055 1.08163,4.44315c0.46939,0.9873 1.55102,2.4684 1.81633,2.8238c1.53061,2.0142 7.10204,8.0569 7.10204,8.0569c0,0 5.5918,-6.0427 7.102,-8.0569c0.2653,-0.3554 1.347,-1.8167 1.8164,-2.8238c0.653,-1.3626 1.0816,-2.8831 1.0816,-4.44315c0,-5.3515 -4.4694,-9.67615 -10,-9.67615z" />
        <path id="svg_2" fill="white" d="m9.99998,17.7923c4.63242,0 8.38772,-3.6337 8.38772,-8.11613c0,-4.4824 -3.7553,-8.11611 -8.38772,-8.11611c-4.63242,0 -8.38775,3.63371 -8.38775,8.11611c0,4.48243 3.75533,8.11613 8.38775,8.11613z" />
        <path id="svg_3" fill={svgColor} d="m10,16.331c3.7984,0 6.8776,-2.9795 6.8776,-6.65486c0,-3.67535 -3.0792,-6.65482 -6.8776,-6.65482c-3.79836,0 -6.87755,2.97947 -6.87755,6.65482c0,3.67536 3.07919,6.65486 6.87755,6.65486z" />
        <path id="svg_4" fill="white" d="m13.1429,7.80014c1.2623,0 2.2857,-0.99021 2.2857,-2.21169c0,-1.22148 -1.0234,-2.2117 -2.2857,-2.2117c-1.2624,0 -2.2858,0.99022 -2.2858,2.2117c0,1.22148 1.0234,2.21169 2.2858,2.21169z" />
      </g>
    </svg>
  );
};

const locationSvgString = (fillColor) => {
  return `<svg width="16" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">
  <g>
    {/* <title>Layer 1</title> */}
    <circle id="svg_1" fill=${fillColor} r="6" cy="8" cx="8" />
    <ellipse
      id="svg_2"
      fill="white"
      ry="1.965"
      rx="2.1"
      cy="3.91617"
      cx="11.7504"
    />
    <path
      id="svg_3"
      fill=${fillColor}
      d="m16,8c0,6 -8,12 -8,12c0,0 -8,-6 -8,-12c0,-4.41828 3.58172,-8 8,-8c4.4183,0 8,3.58172 8,8zm-14.95166,0c0,3.8393 3.11236,6.9517 6.95166,6.9517c3.8393,0 6.9517,-3.1124 6.9517,-6.9517c0,-3.8393 -3.1124,-6.95166 -6.9517,-6.95166c-3.8393,0 -6.95166,3.11236 -6.95166,6.95166z"
    />
  </g>
</svg>`
}

const ProductSvg = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="20" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        {/* <title>Layer 1</title> */}
        <path id="svg_1" fill={svgColor} d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z" />
        <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z" />
        <path id="svg_3" fill={svgColor} d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z" />
        <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z" />
      </g>
    </svg>
  );
};

const AmminitySvg = ({ color }) => {
  const svgColor = color;
  return (


    <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        <title>Layer 1</title>
        <path id="svg_1" fill={svgColor} d="m10.5,0.70001c-5.41667,0 -10,4.37501 -10,9.99999c0,5.4167 4.375,10 10,10c5.4167,0 10,-4.375 10,-10c-0.2083,-5.62498 -4.5833,-9.99999 -10,-9.99999zm-3.95833,2.70834c0.625,0 1.25,0.62501 1.25,1.25001c0,0.625 -0.625,1.24998 -1.25,1.24998c-0.625,0 -1.25,-0.62498 -1.25,-1.24998c0,-0.625 0.625,-1.25001 1.25,-1.25001zm2.70833,7.91665c0,0.625 -1.04166,0.625 -1.04166,0l0,-3.33333l-0.20834,0l0,9.16673c0,1.0416 -1.45833,0.8333 -1.45833,0l0,-5.4167l-0.20834,0l0,5.4167c0,0.8333 -1.45833,1.0416 -1.45833,0l0,-9.16673l-0.20833,0l0,3.33333c0,0.625 -1.04167,0.625 -1.04167,0l0,-3.74999c0,-0.83333 0.625,-1.45832 1.45834,-1.45832l2.70833,0c0.83333,0 1.45833,0.83334 1.45833,1.66667l0,3.54164zm5,-7.91665c0.625,0 1.25,0.41668 1.25,1.25001c0,0.83334 -0.4167,1.24998 -1.25,1.24998c-0.8333,0 -1.25,-0.41664 -1.25,-1.24998c0,-0.83333 0.625,-1.25001 1.25,-1.25001zm2.2917,7.50005l-0.8334,-3.12504l-0.2083,0l1.6667,5.41664l-1.4584,0l0,3.9584c0,0.625 -1.0416,0.625 -1.0416,0l0,-3.9584l-0.625,0l0,3.9584c0,0.625 -1.0417,0.625 -1.0417,0l0,-3.9584l-1.4583,0l1.6666,-5.41664l-0.2083,0l-0.8333,3.12504c-0.2084,0.625 -1.0417,0.4166 -0.8334,-0.4167l1.0417,-3.33335c0,-0.41666 0.625,-1.04166 1.4583,-1.04166l1.6667,0c0.8333,0 1.25,0.625 1.4583,1.04166l1.0417,3.33335c-0.2083,0.8333 -1.0417,1.0417 -1.4583,0.4167z" />
      </g>
    </svg>
    //  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

    //  <g>
    //   <title>Layer 1</title>
    //   <path id="svg_1" fill={svgColor} d="m4.04144,3.35998c0.34695,-0.00003 0.68606,-0.09908 0.97423,-0.28458c0.28818,-0.1855 0.51241,-0.44908 0.64421,-0.75723c0.1318,-0.30814 0.16521,-0.64693 0.09599,-0.97335c-0.06922,-0.32642 -0.23794,-0.62572 -0.48473,-0.85986c-0.2468,-0.23413 -0.56052,-0.39253 -0.90129,-0.45507c-0.34078,-0.06255 -0.69323,-0.02644 -1.01256,0.10379c-0.31934,0.13022 -0.59114,0.34867 -0.78088,0.62756c-0.18973,0.27889 -0.28883,0.60565 -0.2847,0.93874c0.00549,0.44209 0.19225,0.8643 0.5198,1.17505c0.32754,0.31074 0.76946,0.48498 1.22993,0.48495zm1.7914,0.35998l-3.64528,0c-0.29174,0.00259 -0.58005,0.06069 -0.84827,0.1709c-0.26822,0.11021 -0.51104,0.27037 -0.71441,0.47122c-0.20338,0.20085 -0.36328,0.43841 -0.47047,0.69894c-0.10718,0.26053 -0.15953,0.53886 -0.15402,0.81893l0,5.07995c0,0.191 0.07901,0.3741 0.21964,0.5092c0.14063,0.135 0.33137,0.2108 0.53025,0.2108c0.19888,0 0.38962,-0.0758 0.53025,-0.2108c0.14063,-0.1351 0.21964,-0.3182 0.21964,-0.5092l0,-4.65993l0.35411,0l0,12.67993c0,0.2652 0.10973,0.5196 0.30505,0.7071c0.19532,0.1875 0.46023,0.2929 0.73646,0.2929c0.27622,0 0.54113,-0.1054 0.73645,-0.2929c0.19532,-0.1875 0.30506,-0.4419 0.30506,-0.7071l0,-7.36l0.3541,0l0,7.36c0,0.2652 0.10973,0.5196 0.30505,0.7071c0.19532,0.1875 0.46024,0.2929 0.73646,0.2929c0.27622,0 0.54114,-0.1054 0.73646,-0.2929c0.19532,-0.1875 0.30505,-0.4419 0.30505,-0.7071l0,-12.67993l0.3541,0l0,4.65993c0,0.191 0.07901,0.3741 0.21964,0.5092c0.14063,0.135 0.33137,0.2108 0.53025,0.2108c0.19888,0 0.38962,-0.0758 0.53025,-0.2108c0.14063,-0.1351 0.21964,-0.3182 0.21964,-0.5092l0,-5.05993c0.00022,-0.56792 -0.23039,-1.1135 -0.64277,-1.52065c-0.41237,-0.40714 -0.97395,-0.64373 -1.56522,-0.65936l-0.18747,0z"/>
    //   <path id="svg_2" fill={svgColor} d="m15.3114,3.32009c0.3296,0 0.6518,-0.09384 0.9258,-0.26965c0.2741,-0.17581 0.4877,-0.42568 0.6138,-0.71804c0.1261,-0.29236 0.1591,-0.61407 0.0948,-0.92444c-0.0643,-0.31037 -0.223,-0.59545 -0.456,-0.81921c-0.2331,-0.22376 -0.53,-0.37616 -0.8533,-0.43789c-0.3232,-0.06174 -0.6583,-0.03004 -0.9628,0.09106c-0.3045,0.1211 -0.5647,0.32618 -0.7478,0.58929c-0.1831,0.26312 -0.2809,0.57244 -0.2809,0.88888c0,0.21012 0.0431,0.41819 0.1269,0.61231c0.0837,0.19412 0.2065,0.37049 0.3612,0.51907c0.1547,0.14857 0.3385,0.26642 0.5406,0.34683c0.2022,0.0804 0.4189,0.12179 0.6377,0.12179zm4.6035,6.53998l-1.4581,-4.63999c-0.1545,-0.41599 -0.4358,-0.77769 -0.8073,-1.03808c-0.3715,-0.2604 -0.816,-0.40741 -1.2757,-0.42191l-2.2497,0c-0.4635,0.00002 -0.9151,0.14146 -1.2895,0.40387c-0.3743,0.26241 -0.6522,0.63223 -0.7935,1.05612l-1.4581,4.63999c-0.2916,1.00003 1.0207,1.38003 1.3331,0.46003l1.3123,-4.28002l0.4375,0l-2.2705,7.59992l2.083,0l0,5.56c0,0.2016 0.0834,0.3949 0.2318,0.5374c0.1485,0.1426 0.3498,0.2226 0.5597,0.2226c0.21,0 0.4113,-0.08 0.5597,-0.2226c0.1485,-0.1425 0.2319,-0.3358 0.2319,-0.5374l0,-5.5799l0.4791,0l0,5.6199c0,0.2016 0.0834,0.3949 0.2318,0.5374c0.1484,0.1425 0.3498,0.2226 0.5597,0.2226c0.2099,0 0.4113,-0.0801 0.5597,-0.2226c0.1485,-0.1425 0.2319,-0.3358 0.2319,-0.5374l0,-5.5599l2.083,0l-2.3122,-7.60001l0.3958,0l1.3123,4.28001c0.0229,0.0923 0.0656,0.179 0.1254,0.2545c0.0599,0.0755 0.1355,0.1382 0.2221,0.1839c0.0866,0.0458 0.1822,0.0736 0.2807,0.0818c0.0984,0.0081 0.1976,-0.0036 0.291,-0.0344c0.0935,-0.0309 0.1792,-0.0802 0.2516,-0.1447c0.0725,-0.0646 0.13,-0.143 0.169,-0.2302c0.039,-0.0872 0.0585,-0.1813 0.0574,-0.2762c-0.0012,-0.0948 -0.023,-0.1884 -0.0641,-0.27474l-0.0208,-0.05999z"/>
    //  </g>
    // </svg>

  );
};

const SafetySvg = ({ color }) => {
  const svgColor = color;
  return (

    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        <title>Layer 1</title>
        <path id="svg_1" fill={svgColor} d="m2.00346,0l16.02124,0c1.0974,0 1.9753,0.87912 1.9753,1.97803l0,16.04397c0,1.0989 -0.8779,1.978 -1.9753,1.978l-16.02124,0c-1.09735,0 -1.97521,-0.8791 -1.97521,-1.978l0,-16.04397c-0.21947,-0.87913 0.87786,-1.97803 1.97521,-1.97803z" />
        <path id="svg_2" fill="#FAFAFA" d="m17.585,7.25273l-4.6088,0l0,-4.61539c0,-0.43956 -0.2195,-0.65934 -0.6584,-0.65934l-4.60889,0c-0.43894,0 -0.65845,0.21978 -0.65845,0.65934l0,4.61539l-4.60881,0c-0.43894,0 -0.65845,0.21977 -0.65845,0.65934l0,4.61543c0,0.4395 0.21951,0.6593 0.65845,0.6593l4.60881,0l0,4.6154c0,0.4395 0.21951,0.6593 0.65845,0.6593l4.60889,0c0.4389,0 0.6584,-0.2198 0.6584,-0.6593l0,-4.6154l4.6088,0c0.439,0 0.6584,-0.2198 0.6584,-0.6593l0,-4.61543c0,-0.43957 -0.2194,-0.65934 -0.6584,-0.65934z" />
      </g>
    </svg>
  );
};

const BeaconSvg = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        {/* <title>Layer 1</title> */}
        <path id="svg_1" fill={svgColor} d="m9,18c4.9705,0 9,-4.0295 9,-9c0,-4.97057 -4.0295,-9 -9,-9c-4.97057,0 -9,4.02943 -9,9c0,4.9705 4.02943,9 9,9zm0,-1.2857c-4.14213,0 -7.71429,-3.5722 -7.71429,-7.7143c0,-4.14213 3.57216,-7.71429 7.71429,-7.71429c4.1421,0 7.7143,3.57216 7.7143,7.71429c0,4.1421 -3.5722,7.7143 -7.7143,7.7143z" />
        <path id="svg_2" fill={svgColor} d="m8.99986,15.4294c3.55044,0 6.42854,-2.8782 6.42854,-6.42856c0,-3.55041 -2.8781,-6.42857 -6.42854,-6.42857c-3.5504,0 -6.42857,2.87816 -6.42857,6.42857c0,3.55036 2.87817,6.42856 6.42857,6.42856z" />
        <path id="svg_3" fill="#F5F6F7" d="m11.999,3.21673c-0.5068,0.04253 -0.9896,0.23438 -1.3874,0.5513c-0.3977,0.31692 -0.69259,0.74468 -0.84725,1.22917c-0.15465,0.4845 -0.16216,1.00397 -0.02158,1.49274c0.14058,0.48876 0.42293,0.92487 0.81133,1.25316c0.3885,0.32829 0.8655,0.53403 1.3709,0.59119c0.5053,0.05716 1.0163,-0.03681 1.4682,-0.27005c0.452,-0.23323 0.8246,-0.59524 1.0708,-1.04025c0.2462,-0.44502 0.355,-0.95305 0.3124,-1.45985c0,0 -0.6338,-1.0708 -1.2767,-1.39223c-0.6213,-0.31068 -0.9939,-0.9977 -1.5007,-0.95518z" />
      </g>
    </svg>
  );
};

const ChangeSvgColorPassingBE = (svg, color) => {
  const originalSvgString = svg

  // Desired color (e.g., "#FF0000" for red)
  const desiredColor = color;

  // Create a DOM element from the SVG string
  const parser = new DOMParser();
  const doc = parser.parseFromString(originalSvgString, 'image/svg+xml');
  const svgElement = doc.documentElement;

  // Find the <path> element with id="svg_1"
  const pathElement = svgElement.querySelector('#svg_1');

  // Update the "fill" attribute of the <path> element with the desired color
  pathElement.setAttribute('fill', desiredColor);

  // Serialize the updated SVG element back to a string
  const updatedSvgString = new XMLSerializer().serializeToString(svgElement);

  // Now, updatedSvgString contains the modified SVG with the desired color
  return updatedSvgString

}


const VerticalTransportSvg = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg" fill="none">

      <g>
        <title>Layer 1</title>
        <path id="svg_1" fill={svgColor} d="m10.0996,20.1c5.5228,0 10,-4.4771 10,-10c0,-5.52284 -4.4772,-9.99999 -10,-9.99999c-5.52287,0 -9.99999,4.47715 -9.99999,9.99999c0,5.5229 4.47712,10 9.99999,10z" />
        <path id="svg_2" fill="white" d="m16.6936,15.2064l0,-1.4893l-2.9787,0l0,-2.9788l-2.766,0l0,-2.97869l-2.97873,0l0,-2.76596l-4.25533,0l0,1.70213l2.766,0l0,2.76595l2.76594,0l0,2.97877l2.97872,0l0,2.7659l4.4681,0z" />
      </g>
    </svg>
  );
};

const LocationSvgSmall = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="15" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

 <g>
  <title>Layer 1</title>
  <path id="svg_1" fill={svgColor} d="m7.49239,0.00002c-1.98709,0 -3.89283,0.81618 -5.29792,2.26903c-1.40509,1.45285 -2.19447,3.42335 -2.19447,5.47799c0.01815,1.23241 0.29464,2.44606 0.8104,3.55736c0.39861,0.7825 0.85353,1.5329 1.36087,2.245c1.14679,1.581 5.32112,6.4506 5.32112,6.4506c0,0 4.18961,-4.8221 5.33641,-6.4506c0.5019,-0.7159 0.9565,-1.4659 1.3608,-2.245c0.5158,-1.1113 0.7922,-2.32495 0.8104,-3.55736c0,-1.01869 -0.1943,-2.02737 -0.5718,-2.96831c-0.3774,-0.94095 -0.9307,-1.79569 -1.628,-2.51528c-0.6974,-0.71958 -1.5252,-1.28988 -2.4359,-1.67827c-0.91081,-0.38839 -1.88671,-0.58724 -2.87191,-0.58516z"/>
  <path id="svg_2" fill="white" d="m7.49341,14.2448c3.47079,0 6.28439,-2.9092 6.28439,-6.498c0,-3.58876 -2.8136,-6.49802 -6.28439,-6.49802c-3.47078,0 -6.28443,2.90926 -6.28443,6.49802c0,3.5888 2.81365,6.498 6.28443,6.498z"/>
  <path id="svg_3" fill={svgColor} d="m7.49474,13.0748c2.84586,0 5.15286,-2.3854 5.15286,-5.32805c0,-2.9426 -2.307,-5.32805 -5.15286,-5.32805c-2.84587,0 -5.15294,2.38545 -5.15294,5.32805c0,2.94265 2.30707,5.32805 5.15294,5.32805z"/>
  <path id="svg_4" fill="white" d="m9.86292,6.22923c0.94578,0 1.71258,-0.79278 1.71258,-1.77074c0,-0.97796 -0.7668,-1.77075 -1.71258,-1.77075c-0.94581,0 -1.71253,0.79279 -1.71253,1.77075c0,0.97796 0.76672,1.77074 1.71253,1.77074z"/>
 </g>
</svg>
    
  );
};

const ProductSvgSmall = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="15" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

 <g>
  <title>Layer 1</title>
  <path id="svg_1" fill={svgColor} d="m7.46421,0.00036c-1.97962,0 -3.8782,0.8162 -5.27801,2.26906c-1.3998,1.45286 -2.1862,3.42335 -2.1862,5.478c0.01603,1.23097 0.28979,2.44358 0.80229,3.55388c0.41861,0.7919 0.8977,1.5477 1.43266,2.2601c1.13181,1.606 5.30088,6.4386 5.30088,6.4386c0,0 4.16907,-4.8326 5.30087,-6.4386c0.5103,-0.715 0.9653,-1.4707 1.361,-2.2601c0.5125,-1.1103 0.7863,-2.32291 0.8023,-3.55388c0,-1.02365 -0.1954,-2.03714 -0.5749,-2.98194c-0.3796,-0.94479 -0.9359,-1.80216 -1.6366,-2.52252c-0.7007,-0.72036 -1.532,-1.28945 -2.4459,-1.67437c-0.91386,-0.38493 -1.89216,-0.57806 -2.87839,-0.56823z"/>
  <path id="svg_2" fill="white" d="m7.46391,14.2453c3.45769,0 6.26069,-2.9093 6.26069,-6.49801c0,-3.58876 -2.803,-6.49802 -6.26069,-6.49802c-3.45772,0 -6.26079,2.90926 -6.26079,6.49802c0,3.58871 2.80307,6.49801 6.26079,6.49801z"/>
  <path id="svg_3" fill={svgColor} d="m7.46295,13.0705c2.83265,0 5.12895,-2.3834 5.12895,-5.32334c0,-2.93999 -2.2963,-5.32333 -5.12895,-5.32333c-2.83264,0 -5.12897,2.38334 -5.12897,5.32333c0,2.93994 2.29633,5.32334 5.12897,5.32334z"/>
  <path id="svg_4" fill="white" d="m7.46336,9.53186c0.94948,0 1.71917,-0.7989 1.71917,-1.78437c0,-0.98548 -0.76969,-1.78436 -1.71917,-1.78436c-0.94949,0 -1.71922,0.79888 -1.71922,1.78436c0,0.98547 0.76973,1.78437 1.71922,1.78437z"/>
 </g>
</svg>
    
  );
};

const TraversableSvg = ({ color }) => {
  const svgColor = color;
  return (
    <FaRoute style={{ color: svgColor }} />

  );
};

const LayersSvg = ({ color }) => {
  const svgColor = color;
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g>
        <title>Layer 1</title>
        <path d="m9.99375,16.8955c-0.10238,0.0272 -0.21058,0.0272 -0.31296,0l-9.26344,
  -4.6238c-0.18068,-0.0969 -0.31582,-0.2561 -0.37785,-0.445c-0.06204,-0.1889 -0.04629,-0.3932 0.04403,-0.5714c0.03592,-0.088 0.0907,-0.1679 0.16083,-0.2344c0.07012,-0.0666 0.15404,-0.1185 0.24637,-0.1522c0.09233,-0.0338 0.19104,-0.0488 0.28978,-0.044c0.09875,0.0048 0.19535,0.0293 0.28362,0.0718l8.92962,4.4843l8.92965,-4.4843c0.0898,-0.0428 0.1879,-0.0674 0.2881,-0.0724c0.1002,-0.005 0.2004,0.0098 0.2944,0.0434c0.094,0.0337 0.1797,0.0855 0.2518,0.1522c0.072,0.0667 0.129,0.1469 0.1671,0.2356c0.0808,0.1795 0.0916,0.3808 0.0303,0.5673c-0.0612,0.1865 -0.1904,0.3458 -0.3641,0.4491l-9.2634,4.5441c-0.1009,0.0569 -0.2168,0.0846 -0.33385,0.0797zm0,3.0892c-0.10347,0.0179 -0.20948,0.0179 -0.31296,0l-9.26344,-4.6437c-0.18068,-0.097 -0.31582,-0.2562 -0.37785,-0.445c-0.06204,-0.1889 -0.04629,-0.3933 0.04403,-0.5715c0.03529,-0.0883 0.0901,-0.1683 0.16069,-0.2346c0.07059,-0.0662 0.15531,-0.1172 0.24837,-0.1493c0.09307,-0.0322 0.19229,-0.0448 0.29092,-0.037c0.09863,0.0078 0.19435,0.0358 0.28062,0.0821l8.92962,4.4643l8.92965,-4.4643c0.0885,-0.0434 0.1853,-0.0691 0.2846,-0.0754c0.0993,-0.0064 0.1988,0.0067 0.2926,0.0385c0.0938,0.0317 0.1798,0.0814 0.2527,0.146c0.073,0.0646 0.1313,0.1428 0.1715,0.2297c0.0854,0.1789 0.0985,0.3817 0.0369,0.5693c-0.0616,0.1876 -0.1936,0.3469 -0.3707,0.4472l-9.2634,4.6437c-0.1103,0.0204 -0.2236,0.0204 -0.33385,0zm0,-6.1784c-0.10238,0.0272 -0.21058,0.0272 -0.31296,0l-9.26344,-4.64367c-0.18068,-0.09696 -0.31582,-0.25611 -0.37785,-0.445c-0.06204,-0.18888 -0.04629,-0.39326 0.04403,-0.57143c0.02049,-0.10623 0.06653,-0.20646 0.13448,-0.2928c0.06795,-0.08634 0.15596,-0.15642 0.2571,-0.20472c0.10114,-0.04831 0.21264,-0.07353 0.32571,-0.07365c0.11308,-0.00013 0.22464,0.02484 0.32589,0.07292l8.92959,4.48425l8.8671,-4.48425c0.089,-0.04059 0.1856,-0.06402 0.2841,
  -0.06896c0.0985,-0.00494 0.1971,0.00872 0.2901,0.04018c0.093,0.03146 0.1786,0.08012 0.2519,0.14319c0.0733,0.06307 0.1329,0.13932 0.1753,0.22439c0.0854,0.17893 0.0985,0.38168 0.0369,0.5693c-0.0616,0.18762 -0.1936,0.34687 -0.3707,0.44714l-9.2634,4.64371c-0.0909,0.0854 -0.2078,0.1412 -0.33385,0.1594zm0,-3.0891c-0.10347,0.0179 -0.20948,0.0179 -0.31296,0l-9.26344,-4.64373c-0.12633,-0.06732 -0.23159,-0.1656 -0.30493,-0.2847c-0.07335,-0.11911 -0.11212,-0.25474 -0.11234,-0.39293c-0.00203,-0.13861 0.03581,-0.27509 0.10938,-0.39458c0.07358,-0.11948 0.18008,-0.21739 0.30789,-0.28304l9.26344,-4.64371c0.10009,-0.04897 0.21094,-0.07451 0.32341,-0.07451c0.1124,0 0.2233,0.02554 0.3234,0.07451l9.2634,4.64371c0.1255,0.06657 0.2291,0.16526 0.2991,0.28496c0.07,0.1197 0.1037,0.25568 0.0973,0.39266c0.0041,0.13661 -0.0306,0.27172 -0.1004,0.39102c-0.0698,0.1193 -0.1721,0.21833 -0.296,0.28661l-9.2634,4.56403c-0.1047,0.0473 -0.2182,0.0744 -0.33385,0.0797z" fill={svgColor} id="svg_1" />
      </g>

    </svg>
  );
};

export {
  LocationSvg,
  ProductSvg,
  AmminitySvg,
  locationSvgString,
  BeaconSvg,
  ChangeSvgColorPassingBE,
  VerticalTransportSvg,
  TraversableSvg,
  LocationSvgSmall,
  ProductSvgSmall,
  LayersSvg,
  SafetySvg
};


