import React from 'react'
import PSSideBar from './components/PSSideBar'

const ProjectSettings = () => {
  return (
    <div className='ps-main-container' >
      <PSSideBar />
    </div>
  )
}

export default ProjectSettings