import { IoMdClose } from "react-icons/io";
import { Button, Card, CardBody, Col, Label, Modal, ModalBody, Table, ModalHeader, Row } from "reactstrap";
import { additionalSvg, customRound, proSvg } from "../constants/constant";
import { getRequestForDownload } from "../../../../hooks/axiosClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../BuildProject.css';
import { useState } from "react";

const ProPinModal = ({
    isOpen,
    toggle,
    totalPinsUsed,
    planDetails,
    addCardDetails,
    projectSettings
}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size='sm' style={{ zIndex: '999999 !important', color: '#000' }} centered>
            <ModalHeader style={{ padding: '25px 25px 15px 25px', fontSize: '1.5rem' }}>
                <div className='d-flex justify-space-between'>
                    <span>
                        You've Hit Your Limit!
                    </span>
                    <div className='ml-2  rounded-circle payment-close-btn' onClick={() => toggle()} >
                        <IoMdClose fontSize={15} />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody style={{ padding: '0px 32px 32px 32px', fontSize: '0.875rem', fontWeight: '500' }}>
                <Card >
                    <p>You've reached the maximum number of pins for your current plan:</p>
                    <ul className='ulStyles' style={{ marginTop: '10px' }}>
                        <li>
                            <span className={`${totalPinsUsed?.used_locations == totalPinsUsed?.total_locations ? 'red' : ''}`}>{totalPinsUsed?.used_locations}</span>/{totalPinsUsed?.total_locations} Location Pins
                        </li>
                        <li>
                            <span className={`${totalPinsUsed?.used_products == totalPinsUsed?.total_products ? 'red' : ''}`}>{totalPinsUsed?.used_products}</span>/{totalPinsUsed?.total_products} Product Pins
                        </li>
                    </ul>
                    <p style={{ marginTop: '10px' }}>
                        {((planDetails?.plan?.free_expired == 1) && (planDetails?.plan?.basic_expired == 0) && (planDetails?.plan?.additional_expired == 0)) ? 'Please upgrade your plan to increase your total pin limit.' : 'Please purchase additional pins to increase your total pin limit.'}
                    </p>

                    <Card className="cardContainer">

                        <CardBody className='greycard'>
                            <div className="svgContainer">
                                {((planDetails?.plan?.free_expired == 1) && (planDetails?.plan?.basic_expired == 0) && (planDetails?.plan?.additional_expired == 0)) ? proSvg : additionalSvg}

                            </div>
                            <h1 style={{ fontSize: '1.5rem', fontWeight: '600' }}>{(planDetails?.plan?.free_expired == 1 && planDetails?.plan?.basic_expired == 0 && planDetails?.plan?.additional_expired == 0) ? 'Pro' : (planDetails?.plan?.free_expired == 1 && planDetails?.plan?.basic_expired == 1 && planDetails?.plan?.additional_expired == 0) ? 'Additional' : 'Additional +'}</h1>
                            <ul className='ulStyles'>
                                <li>
                                    {
                                        (planDetails?.plan?.free_expired == 1 && planDetails?.plan?.basic_expired == 0 && planDetails?.plan?.additional_expired == 0)
                                            ?
                                            `$${customRound(((planDetails?.plan?.basic_cost) - ((planDetails?.plan?.basic_cost) * (planDetails?.plan?.discount / 100))), 2)}`
                                            :
                                            `$${customRound(((planDetails?.plan?.additional_cost) - ((planDetails?.plan?.additional_cost) * (planDetails?.plan?.discount / 100))), 2)}`} / month
                                </li>
                                <li>
                                    {(planDetails?.plan?.free_expired == 1 && planDetails?.plan?.basic_expired == 0 && planDetails?.plan?.additional_expired == 0) ? `${planDetails?.plan?.basic_location_pins}` : `${planDetails?.plan?.additional_location_pins}`}   Additional Location Pins
                                </li>
                                <li>
                                    {(planDetails?.plan?.free_expired == 1 && planDetails?.plan?.basic_expired == 0 && planDetails?.plan?.additional_expired == 0) ? `${planDetails?.plan?.basic_product_pins}` : `${planDetails?.plan?.additional_product_pins}`}  Additional Product Pins
                                </li>
                            </ul>
                        </CardBody>
                        <Button className={` ${(planDetails?.plan?.free_expired == 1) && (planDetails?.plan?.basic_expired == 0) && (planDetails?.plan?.additional_expired == 0) ? 'btn-warning ' : 'btn-successs'}`}
                            onClick={() => {
                                if ((planDetails?.plan?.free_expired == 1) && (planDetails?.plan?.basic_expired == 0) && (planDetails?.plan?.additional_expired == 0) && projectSettings?.status == 0 ) {
                                    toast.warning('Project is inactive! To upgrade to the pro plan, please activate your project.')
                                } else {
                                    addCardDetails(planDetails?.plan)
                                }
                            }
                            }
                        >
                            Proceed to Payment
                        </Button>
                    </Card>
                </Card >
            </ModalBody>
        </Modal>
    )
}

const GenerateQrModal = ({
    isOpen,
    toggle,
    projectSettings,
    filteredData,
}) => {
    const [loading, setLoading] = useState(false);

    const generateQrcode = async (row, type) => {
        setLoading(true);
        try {
            const response = await getRequestForDownload(`generate-qr/${row?.enc_id}/${type}`);
            console.log(response)
            const dataRes = response.data;
            const blob = new Blob([dataRes], {
                type: "application/pdf",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const pdfName = row?.beacon_name
            link.setAttribute("download", `${pdfName}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast.success('QR Code beacon poster generated successfully.');
            // createNotification('success', 'Student pin details downloaded successfully.', 'filled');
        } catch (error) {
            console.log(error)
            if (error.response.status === 400) {
                toast.error("Please publish the project to generate QR Code Beacon.")
            }
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} style={{ zIndex: '999999 !important', maxWidth: '700px' }} centered>

                <ModalHeader toggle={toggle} style={{ padding: '33px 32px 0px 32px' }}>
                    Generate QR Code Beacon Poster
                </ModalHeader>
                <ModalBody className="modalbody-padding" >
                    <Row >
                        <Col md={12}>
                            <div className="mb-2">
                                <Label style={{ color: '#6a6d73', fontSize: '0.875rem', fontWeight: 'bold', marginTop: '11.48px' }}>{projectSettings?.project_name}</Label>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12}>
                            <Table className="custom-table">
                                <thead>
                                    <tr>
                                        <th className='theadStyle'>QR Code Beacon</th>
                                        <th className='theadStyle'>Print</th>
                                        <th className='theadStyle'>Web</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.length > 0 ? (
                                        filteredData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.beacon_name}</td>
                                                <td className='d-flex'>
                                                    <button className="btn btn-sm buttoninfo mr-2" onClick={() => generateQrcode(item, 1)}>A4</button>{" "}
                                                    <button className="btn btn-sm buttoninfo" onClick={() => generateQrcode(item, 2)}>A3</button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-sm buttoninfo" onClick={() => generateQrcode(item, 3)}>FHD</button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col md={12}>
                            <Button color="secondary" className="btn btnCancel float-right" onClick={toggle}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>

                </ModalBody>
            </Modal>
            {loading &&
                <Modal isOpen={true} size="sm" className="loading-modal" style={{ zIndex: '9999999 !important', maxWidth: '200px', backgroundColor: 'transparent' }} centered>
                    <ModalBody >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <div class="folder">
                                <span class="folder-tab"></span>
                                <div class="folder-icn">
                                    <div class="downloading">
                                        <span class="custom-arrow"></span>
                                    </div>
                                    <div class="bar-downld"></div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </>

    )
}

export {
    ProPinModal,
    GenerateQrModal,
}