import React from 'react';
import { Modal, ModalBody, Button, Row, Col, Label } from 'reactstrap';
import { Formik, Field } from 'formik';

const EditProjectModal = ({ modal, toggle, initialValues, validationSchema, handleSubmitProject }) => {
    return (
        
        <Modal
            isOpen={modal}
            toggle={toggle}
            size="md"
            style={{ zIndex: "999999 !important" }}
            centered
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, setFieldError) => {
                    handleSubmitProject(values, setFieldError);
                }}
                enableReinitialize
            >
                {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    setFieldError
                }) => (
                    <form
                        className="av-tooltip tooltip-label-bottom formGroups"
                        onSubmit={(e) => handleSubmit(e, setFieldError)}
                    >
                        <ModalBody className=" ">
                            <h5 className="f-w-600 mb-4" style={{ fontSize: "18px" }}>
                                {values?.enc_id ? "Edit" : "Add New"} Project
                            </h5>
                            <Row className="">
                                <Col md={12}>
                                    <div className="mb-2">
                                        <Label for="fName" className="form-labels">
                                            Project Name
                                        </Label>
                                        <span className="asterisk">*</span>
                                        <div className="d-flex">
                                            <Field
                                                id="fName"
                                                className="form-control"
                                                type="text"
                                                name="project_name"
                                                placeholder="Please Type"
                                            />
                                        </div>
                                        {errors.project_name && touched.project_name ? (
                                            <div className="text-danger mt-1">
                                                {errors.project_name}
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <div
                                className="form-group text-right "
                                style={{ marginTop: "30px" }}
                            >
                                <Button
                                    color="secondary"
                                    className="btn btnCancel mr-3"
                                    onClick={toggle}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    className="btn btn-primary float-right"
                                >
                                    Save
                                </Button>
                            </div>
                        </ModalBody>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}
export default EditProjectModal;