import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Row, Col, Label, Spinner } from 'reactstrap';
import { Stepper, Step } from 'react-form-stepper';
import UploadExcel from '../BulkUploadSteps/uploadExcel';
import UploadedData from '../BulkUploadSteps/uploadedData';
import { FaCheck } from 'react-icons/fa';
import Finalize from '../BulkUploadSteps/Finalize';
import { getRequest, postRequest } from '../../../../hooks/axiosClient';
import { getCurrentUser } from '../../../../helpers/utils';
import { toast } from 'react-toastify';
import { hasDuplicates } from '../calculateDistance';

const BulkUploadPin = ({
    modal,
    setModal,
    type,
    projectSettings,
    selFloorPlanDtls,
    getList,
    handleEnableDisable
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [columnMapping, setColumnMapping] = useState({});
    const [finalData, setFinalData] = useState([]);
    const [dropDownValues, setDropDownValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);


    useEffect(() => {
        if (modal) {
            getDropdownValuesByType(type)
        }
    }, [modal])


    const getDropdownValuesByType = async (type) => {
        try {
            const url = `bulk-dropdown/${type === 'product' ? 1 : 2}`
            const response = await getRequest(url);
            const data = response.data?.columns ?? [];
            if (data?.length > 0) {
                const values = data?.map((item) => ({ ...item, label: item?.value, value: item?.column }))
                setDropDownValues(values)
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleCancel = () => {
        setModal(false)
        setFinalData([])
        setActiveStep(0)
        setExcelData([])
        setColumnMapping({})
        setFile(null)
        setUploadProgress(0)
    }

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
        // prepareDataForBackend()
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const prepareDataForBackend = () => {
        // return
        // const checkSameData = hasDuplicates(columnMapping)
        // console.log(checkSameData, 'checkSameData')
        // if (checkSameData) {
        //     toast.error('Some fields have same values, please check the uploaded file.')
        //     setLoading
        //     return
        // }
        const preparedData = excelData.slice(1).map(row => {
            const mappedRow = {};
            Object.keys(columnMapping).forEach((columnIndex) => {
                const columnKey = columnMapping[columnIndex];
                mappedRow[columnKey] = row[columnIndex];
            });
            return mappedRow;
        });
        // setFinalData(preparedData)
        postMatchedFields(preparedData, type)
        console.log(preparedData, 'postMatchedFields');
    };

    const postMatchedFields = async (matchArray, type) => {
        let value = {
            customer_id: projectSettings?.enc_customer_id ?? getCurrentUser()?.user?.common_id,
            project_id: projectSettings?.enc_id,
            // fp_id: selFloorPlanDtls?.enc_id,
            data: matchArray,
            // is_published: "0",
            // discard: "1",
            // publish: "1",
        }
        try {
            const url = type === 'product' ? 'bulk-product' : 'bulk-location'
            const response = await postRequest(url, value);
            console.log(response, 'response')
            if (response?.type === 1) {
                const data = response.response.data?.data ?? [];
                console.log(data, 'postMatchedFields')
                const reorderedProducts = data.map(product => {
                    const { image_path, ...rest } = product;
                    return { ...rest, image_path };
                });
                console.log(reorderedProducts, 'reorderedProducts')
                getList()
                handleEnableDisable()
                if (type === 'product') {
                    setFinalData(reorderedProducts)
                    handleNext();
                    setLoading(false)
                } else {
                    handleCancel()
                    setLoading(false)
                }
            } else {
                toast.error(response?.errormessage);
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
        }
    };


    const stepperStyleConfig = {
        activeBgColor: '#26a3db',
        activeTextColor: '#fff',
        completedBgColor: 'green',
        completedTextColor: '#fff',
        inactiveBgColor: '#e0e0e0',
        inactiveTextColor: '#757575',
        size: '2em',
        circleFontSize: '1em',
        labelFontSize: '1em',
        borderRadius: '50%',
        fontWeight: 'bold',
        marginTop: '5px',
        completedIcon: <FaCheck color="white" />,
    };

    const steps = ['Upload', 'Match fields', ...(type === 'product' ? ['Finalise'] : [])];


    return (
        <Modal
            isOpen={modal}
            size="xl"
            // style={{ maxWidth: '1000px', zIndex: "999999 !important" }}
            centered
        >
            <ModalBody className=" ">
                <h5 className="f-w-600 mb-4" style={{ fontSize: "18px" }}>
                    {'Bulk Pin Upload'}
                </h5>
                <Row className="">
                    <Col md={12}>
                        <Stepper
                            activeStep={activeStep}
                            styleConfig={stepperStyleConfig}
                        >
                            <Step label='Upload'>
                                {activeStep > 0 ? <FaCheck /> : 1}
                            </Step>
                            <Step label='Match Fields' >
                                {activeStep > 1 ? <FaCheck /> : 2}
                            </Step>
                            {type === 'product' &&
                                <Step label='Finalise' />
                            }

                        </Stepper>
                        <div style={{ margin: '20px 0' }}>
                            {activeStep === 0 &&
                                <div>
                                    <UploadExcel
                                        setExcelData={setExcelData}
                                        excelData={excelData}
                                        handleCancel={handleCancel}
                                        handleNext={handleNext}
                                        file={file}
                                        setFile={setFile}
                                        uploadProgress={uploadProgress}
                                        setUploadProgress={setUploadProgress}
                                    />
                                </div>
                            }
                            {activeStep === 1 &&
                                <div>
                                    <UploadedData
                                        excelData={excelData}
                                        prepareDataForBackend={prepareDataForBackend}
                                        columnMapping={columnMapping}
                                        setColumnMapping={setColumnMapping}
                                        handleCancel={handleCancel}
                                        handleNext={handleNext}
                                        handleBack={handleBack}
                                        type={type}
                                        options={dropDownValues}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                </div>
                            }
                            {activeStep === 2 && <div>
                                <Finalize
                                    // data={finalData?.map((item) => ({ ...item, image: null }))}
                                    data={finalData}
                                    setData={setFinalData}
                                    handleCancel={handleCancel}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                    postMatchedFields={postMatchedFields}
                                    getList={getList}
                                />
                            </div>}
                        </div>
                    </Col>
                </Row>
                {/* <div
                    className="form-group text-right "
                    style={{ marginTop: "30px" }}
                >
                    <Button
                        color="secondary"
                        className="btn btnCancel mr-3"
                        onClick={() => {

                            handleCancel()
                        }}
                    >
                        {'Cancel'}
                    </Button>
                    {activeStep === 1 &&
                        <Button className="btn btn-primary-outline mr-3"  onClick={handleBack}>Back</Button>

                    }
                    
                    <Button color="primary" type="submit" className="btn btn-primary float-right"
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div> */}
            </ModalBody>
        </Modal>
    )
}
export default BulkUploadPin;