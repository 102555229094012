import drawLine from "./drawLine";

const addConnectionBtwnEdges = (key1, key2, graph, canvas) => {
    const selKey = key1;
    const key = key2;
    graph?.addEdge(selKey, key);
    const posits = graph?.getPositions();
    let position1 = posits[selKey];
    let position2 = posits[key];
    if (key1.includes("_") || key2.includes("_")) {
        drawLine(position1, position2, "path", `path$${selKey}$${key}`, canvas,"green");
    } else {
        drawLine(position1, position2, "path", `path$${selKey}$${key}`, canvas);
    }
};
export default addConnectionBtwnEdges;