import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import 'antd/dist/antd.css';
import { useEffect } from 'react';

import ContextProvider from './providers/ContextProvider';
import AppRoutes from './routes/AppRoutes';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
// import interceptorSetup from './hooks/axiosClient'

function App() {

  useEffect(() => {
    // Set up the interceptor once when the component mounts
    // interceptorSetup()
  }, []);

  return (

    <DndProvider backend={HTML5Backend}>
        <ContextProvider>
          <AppRoutes />
        </ContextProvider>
      <ToastContainer theme="colored"
        position="top-right" hideProgressBar="true" autoClose="2000" closeButton={false}
      />
    </DndProvider>
  );
}


export default App;
