export const getLocationPin = (fillColor) => {
    return `<svg width="20" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
<g>
 <title>Layer 1</title>
 <path id="svg_1" fill="${fillColor}" d="m10,0c-5.53061,0 -10,4.32465 -10,9.67615c0,1.56005 0.42857,3.08055 1.08163,4.44315c0.46939,0.9873 1.55102,2.4684 1.81633,2.8238c1.53061,2.0142 7.10204,8.0569 7.10204,8.0569c0,0 5.5918,-6.0427 7.102,-8.0569c0.2653,-0.3554 1.347,-1.8167 1.8164,-2.8238c0.653,-1.3626 1.0816,-2.8831 1.0816,-4.44315c0,-5.3515 -4.4694,-9.67615 -10,-9.67615z"/>
 <path id="svg_2" fill="white" d="m9.99998,17.7923c4.63242,0 8.38772,-3.6337 8.38772,-8.11613c0,-4.4824 -3.7553,-8.11611 -8.38772,-8.11611c-4.63242,0 -8.38775,3.63371 -8.38775,8.11611c0,4.48243 3.75533,8.11613 8.38775,8.11613z"/>
 <path id="svg_3" fill="${fillColor}" d="m10,16.331c3.7984,0 6.8776,-2.9795 6.8776,-6.65486c0,-3.67535 -3.0792,-6.65482 -6.8776,-6.65482c-3.79836,0 -6.87755,2.97947 -6.87755,6.65482c0,3.67536 3.07919,6.65486 6.87755,6.65486z"/>
 <path id="svg_4" fill="white" d="m13.1429,7.80014c1.2623,0 2.2857,-0.99021 2.2857,-2.21169c0,-1.22148 -1.0234,-2.2117 -2.2857,-2.2117c-1.2624,0 -2.2858,0.99022 -2.2858,2.2117c0,1.22148 1.0234,2.21169 2.2858,2.21169z"/>
</g>
</svg>`;
}

export const getProductPin = (fillColor) => {
    return `<svg width="20" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

    <g>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z"/>
     <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z"/>
     <path id="svg_3" fill="${fillColor}" d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z"/>
     <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z"/>
    </g>
   </svg>`;
}

export const getProductPinDragPreview = (fillColor) => {
    const svg = `<svg width="20" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

    <g>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z"/>
     <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z"/>
     <path id="svg_3" fill="${fillColor}" d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z"/>
     <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z"/>
    </g>
   </svg>`;

    const encodedSvg = encodeURIComponent(svg);
    return `data:image/svg+xml;charset=UTF-8,${encodedSvg}`;
}

export const getBeaconPin = (fillColor) => {
    return `<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none">
    <g>
      <title>Layer 1</title>
      <path id="svg_1" fill="${fillColor}" d="m9,18c4.9705,0 9,-4.0295 9,-9c0,-4.97057 -4.0295,-9 -9,-9c-4.97057,0 -9,4.02943 -9,9c0,4.9705 4.02943,9 9,9zm0,-1.2857c-4.14213,0 -7.71429,-3.5722 -7.71429,-7.7143c0,-4.14213 3.57216,-7.71429 7.71429,-7.71429c4.1421,0 7.7143,3.57216 7.7143,7.71429c0,4.1421 -3.5722,7.7143 -7.7143,7.7143z" />
      <path id="svg_2" fill="${fillColor}" d="m8.99986,15.4294c3.55044,0 6.42854,-2.8782 6.42854,-6.42856c0,-3.55041 -2.8781,-6.42857 -6.42854,-6.42857c-3.5504,0 -6.42857,2.87816 -6.42857,6.42857c0,3.55036 2.87817,6.42856 6.42857,6.42856z" />
      <path id="svg_3" fill="#F5F6F7" d="m11.999,3.21673c-0.5068,0.04253 -0.9896,0.23438 -1.3874,0.5513c-0.3977,0.31692 -0.69259,0.74468 -0.84725,1.22917c-0.15465,0.4845 -0.16216,1.00397 -0.02158,1.49274c0.14058,0.48876 0.42293,0.92487 0.81133,1.25316c0.3885,0.32829 0.8655,0.53403 1.3709,0.59119c0.5053,0.05716 1.0163,-0.03681 1.4682,-0.27005c0.452,-0.23323 0.8246,-0.59524 1.0708,-1.04025c0.2462,-0.44502 0.355,-0.95305 0.3124,-1.45985c0,0 -0.6338,-1.0708 -1.2767,-1.39223c-0.6213,-0.31068 -0.9939,-0.9977 -1.5007,-0.95518z" />
    </g>
    </svg>`;
}

export const getAmenityPin = (fillColor) => {
    return `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

    <g>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m10,20c5.5228,0 10,-4.4772 10,-10c0,-5.52285 -4.4772,-10 -10,-10c-5.52285,0 -10,4.47715 -10,10c0,5.5228 4.47715,10 10,10z"/>
     <path id="svg_2" fill="#FAFAFA" d="m10.0001,5.1566c0.668,0 1.2108,-0.54278 1.2108,-1.21087c0,-0.66809 -0.5428,-1.21087 -1.2108,-1.21087c-0.66806,0 -1.21088,0.54278 -1.21088,1.21087c0,0.66809 0.54282,1.21087 1.21088,1.21087zm1.2526,0.27141l-2.54701,0c-0.85596,0 -1.52405,0.68894 -1.52405,1.56575l0,3.69524c0,0.7098 1.04386,0.7098 1.04386,0l0,-3.3821l0.25052,0l0,9.2276c0,0.9603 1.39876,0.9394 1.39876,0l0,-5.3654l0.25052,0l0,5.3654c0,0.9394 1.3988,0.9603 1.3988,0l0,-9.2276l0.2505,0l0,3.3821c0,0.7306 1.0438,0.7306 1.0438,0l0,-3.67436c0,-0.79333 -0.6262,-1.58663 -1.5448,-1.58663l-0.0209,0z"/>
    </g>
   </svg>`;
}

export const getSafetyPin = (fillColor) => {
    return `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">

    <g>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m17.9431,0l-15.88619,0c-1.136,0 -2.05691,0.92091 -2.05691,2.05691l0,15.88619c0,1.136 0.92091,2.0569 2.05691,2.0569l15.88619,0c1.136,0 2.0569,-0.9209 2.0569,-2.0569l0,-15.88619c0,-1.136 -0.9209,-2.05691 -2.0569,-2.05691z"/>
     <path id="svg_2" fill="white" d="m16.6819,5.33801c0,0 -1.159,2.83535 1.904,4.69799l0,-1.98683c0,0 -0.9727,0.0414 -1.904,-2.71116z"/>
     <path id="svg_3" fill="white" d="m14.4038,9.08398c0,0 -0.4967,3.70452 4.2013,4.76002l0,-1.9247c0,0 -2.38,-0.1035 -4.2013,-2.83532z"/>
     <path id="svg_4" fill="white" d="m14.3848,14.8167c0,0 0.0207,3.2492 4.2012,2.8353l0,-1.9247c0,0 -2.3386,0.9313 -4.2012,-0.8899l0,-0.0207z"/>
     <path id="svg_5" fill="white" d="m16.1844,13.4922c-0.3311,-0.3725 -1.0141,-0.8486 -1.0141,-0.8486c-0.9934,-0.6622 -2.1937,0.1656 -2.1937,0.1656c1.0141,0.2277 1.9454,1.428 2.1316,1.6971c0.0414,0.0621 0.0828,0.1242 0.1449,0.1862c0.3519,0.3726 0.8486,0.5588 1.2004,0.6416c0.3104,0.0621 0.6209,0.0828 0.9106,0.0414c0.8899,-0.1242 1.2004,-0.6416 1.2004,-0.6416c-1.3452,-0.1035 -2.4007,-1.2417 -2.4007,-1.2417l0.0206,0z"/>
     <path id="svg_6" fill="white" d="m16.0598,7.6145c0,0 -0.0414,-0.10349 -0.0828,-0.16559c-0.8485,-1.57287 -2.0903,-1.24174 -2.0903,-1.24174c0.5795,0.14489 0.7244,0.91061 0.7244,0.91061c0.1035,0.49672 0.269,1.07619 0.269,1.07619c0.1656,0.47602 0.3932,0.952 0.5381,1.22104c0.1242,0.22765 0.2483,0.43463 0.4139,0.62089c1.2004,1.3866 2.7526,0.8278 2.7526,0.8278c-1.2004,-0.3725 -2.3387,-2.81461 -2.5249,-3.2699l0,0.0207z"/>
     <path id="svg_7" fill="white" d="m7.56082,4.602l-0.47695,0l0,-1.08166l0.87521,0l0.43767,-0.43767l0,-0.65644l-0.43767,-0.43767l-1.09411,0l0,-0.43753l-1.53174,0l0,0.43753l-0.65644,0c-2.84459,1.53178 -3.28226,2.84475 -3.28226,2.84475l0,0.43754l0.65644,0l0,-0.43754c0,0 1.09411,-1.31297 2.18818,-1.53174l0.87534,0l0,1.30043l-0.49562,0c-0.87948,0 -1.59238,0.71306 -1.59238,1.59251l0,12.20539l6.12687,0l0,-12.20536c0,-0.87948 -0.7131,-1.59254 -1.59254,-1.59254z"/>
    </g>
   </svg>`;
}

export const getVerticalPin = (fillColor) => {
    return `
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none">

    <g>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m9,18c4.9705,0 9,-4.0295 9,-9c0,-4.97057 -4.0295,-9 -9,-9c-4.97057,0 -9,4.02943 -9,9c0,4.9705 4.02943,9 9,9zm0,-1.2857c-4.14213,0 -7.71429,-3.5722 -7.71429,-7.7143c0,-4.14213 3.57216,-7.71429 7.71429,-7.71429c4.1421,0 7.7143,3.57216 7.7143,7.71429c0,4.1421 -3.5722,7.7143 -7.7143,7.7143z"/>
     <path id="svg_2" fill="${fillColor}" d="m8.99986,15.4294c3.55044,0 6.42854,-2.8782 6.42854,-6.42856c0,-3.55041 -2.8781,-6.42857 -6.42854,-6.42857c-3.5504,0 -6.42857,2.87816 -6.42857,6.42857c0,3.55036 2.87817,6.42856 6.42857,6.42856z"/>
    </g>
   </svg>`;
}