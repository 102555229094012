const pathLine = (canvas,nodes, projectSettings, shortestPath) => {
    shortestPath.forEach((p, id) => {
        if (id < shortestPath.length - 1) {
            let points = [
                nodes[p].x,
                nodes[p].y,
                nodes[shortestPath[id + 1]].x,
                nodes[shortestPath[id + 1]].y
            ];
            let line = new fabric.Line(points, {
                strokeWidth: projectSettings?.navigation_thick ?? 3,
                stroke: projectSettings?.navigation_color ?? "red",
                selectable: false,
                name: "short_path",
                id: "short_path",
                originX: "center",
                originY: "center",
                hoverCursor: "auto"
            });
            canvas.current.add(line).renderAll();
        }
    });
}
export default pathLine;