import { fabric } from "fabric";

const drawLine = (p1, p2, name = "path", id = "path", canvas, color = "black") => {
    // console.log(id)
    let points = [p1?.x, p1?.y, p2?.x, p2?.y];
    let line = new fabric.Line(points, {
        strokeWidth: 1,
        // stroke: "black",
        stroke: color,
        selectable: false,
        name,
        id,
        perPixelTargetFind: true,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hoverCursor: "auto"
    });
    canvas.current.add(line);
    return line;
};



export default drawLine;