import React, { createContext, useState, } from 'react'

export const AppContext = createContext()

export const ContextProvider = ({ children }) => {

    const [user, setUser] = useState()


    return (
        <AppContext.Provider
            value={{
                user,
                setUser: (values) => setUser(values)

            }}
        >            {children}
        </AppContext.Provider>
    )
}

export default ContextProvider