import {navigationPathZoomLevel, tracingLengthZoomLevel} from "./tracingLengthZoomLevel";

const handleMouseWheel = (options, canvas) => {
    var delta = options.e.deltaY;
    var pointer = canvas.current.getPointer(options.e);

    var zoom = canvas.current.getZoom();
    if (delta > 0) {
        zoom /= 1.1;
    } else {
        zoom *= 1.1;
    }
    if (zoom > 10) zoom = 10;
    if (zoom < 0.1) zoom = 0.1;
    canvas.current.zoomToPoint(
        { x: options.e.offsetX, y: options.e.offsetY },
        zoom
    );
    var canvasCenter = {
        x: canvas.current.width / 2,
        y: canvas.current.height / 2
    };
    // canvas.current.zoomToPoint(
    //   { x: canvasCenter.x, y: canvasCenter.y },
    //   zoom
    // );

    canvas.current.renderAll();
    tracingLengthZoomLevel(canvas, zoom)
    navigationPathZoomLevel(canvas, zoom)

}
export default handleMouseWheel;