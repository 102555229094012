import { findObjectById } from "../bringFabricObjects";

const highLightSelectedPaths = (canvas, objs, color, strokeWidth) => {
    canvas.current.getObjects().forEach((a) => {
        if (a.name === 'path' && objs.includes(a)) {
            a.set('stroke', color);
        } else if (a.name === 'path') {
            if (a.id?.includes("_")) {
                a.set('stroke', 'green');
            } else {
                a.set('stroke', 'black');
            }
           
        }
    });
    canvas.current.renderAll();
}

const nodeLinesSelected = (obj, graph, canvas) => {
    const newColor = '#e78fbc';
    console.log(obj, 'select-node')
    let edges = graph.getEdges();
    let id = obj?.id
    if (edges[id]) {
        console.log(edges[id], 'edges[id]')
        Object.keys(edges[id]).forEach((key) => {
            const line1 = findObjectById(`path$${key}$${id}`, canvas)
            const line2 = findObjectById(`path$${id}$${key}`, canvas)
            const connectedLine = []
            connectedLine.push(line1)
            connectedLine.push(line2)
            console.log(connectedLine, 'connectedLine')
            highLightSelectedPaths(canvas, connectedLine, newColor)
        });
    }
}
export { highLightSelectedPaths, nodeLinesSelected };