import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, Button, Row, Col, Label, Spinner } from 'reactstrap';
import { fabric } from 'fabric';
import { calculateDistance } from '../calculateDistance';
import { Field, Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaMinus, FaPlus } from 'react-icons/fa';
import controllPan from '../controllPan';


const ValidationSchema = Yup.object().shape({
    // points_distance: Yup.string().required('Select two points on your floor plan.'),
    real_world_distance: Yup.string().required('This field is required.'),

})
const ReferenceImageModal = ({
    modal, values, toggle, handleScaleSubmit,
    handleDeleteRefImage, selImageOrSvgValues,
    setSelImageOrSvgValues,
    loading,
    setSelFloorPlanDtls
}) => {
    const [canvas, setCanvas] = useState(null);
    const referenceImgRef = useRef(null);
    const refImageDivRef = useRef(null);
    const isPanning = useRef(false);
    const lastPosX = useRef(0);
    const lastPosY = useRef(0);

    useEffect(() => {
        if (modal && referenceImgRef.current) {
            initCanvas()
        }
    }, [modal, referenceImgRef.current]);

    let isDrawing = false;
    let points = [];
    const initCanvas = () => {
        const newCanvas = new fabric.Canvas(referenceImgRef.current, {
            // width: 800,
            // height: 600,
            width: 2000,
            height: 1000,
            hoverCursor: 'pointer',
            selection: false
        });

        setCanvas(newCanvas);
        if (values?.imageType === 'import-svg') {
            fabric.loadSVGFromURL(values.imageScale, (objects, options) => {
                const svg = fabric.util.groupSVGElements(objects, options);
                svg.set({
                    selectable: false
                })
                newCanvas.add(svg);
                newCanvas.renderAll();
            });
        } else {
            console.log(values?.image,values?.image.type,'values?.image')
            if (values?.image.type !== 'image/svg+xml') {
                fabric.Image.fromURL(values.imageScale, (img) => {
                    newCanvas.setBackgroundImage(img, newCanvas.renderAll.bind(newCanvas), {
                        backgroundImageStretch: false
                    });
                    // newCanvas.setWidth(img.width);
                    // newCanvas.setHeight(img.height);
                    newCanvas.renderAll();
                });
            } else {
              
                fabric.loadSVGFromURL(values.imageScale, (objects, options) => {
                    const svg = fabric.util.groupSVGElements(objects, options);
                    svg.set({
                        selectable: false
                    })
                    newCanvas.add(svg);
                    newCanvas.renderAll();
                });
            }
        }

        newCanvas.on('mouse:down', (event) => {
            handleMouseDown(event, newCanvas)
        });
        newCanvas.on('mouse:move', (event) => {
            newCanvas.upperCanvasEl.hoverCursor = 'pointer';
            newCanvas.upperCanvasEl.style.cursor = 'pointer';
            handleMouseMove(event, newCanvas)
            if (isPanning.current) {
                var delta = new fabric.Point(event.e.movementX, event.e.movementY);
                newCanvas.relativePan(delta);
                newCanvas.defaultCursor = "grab";
                newCanvas.hoverCursor = "grab";
                newCanvas.renderAll();
            }
        });

        newCanvas.on("mouse:up", () => {
            isPanning.current = false;
        });

        newCanvas.on('mouse:wheel', (event) => {
            handleMouseWheel(event, newCanvas)
            event.e.preventDefault();
            event.e.stopPropagation();
        })

        document.addEventListener("mousedown", (opt) => {
            if (opt.button === 1) {
              isPanning.current = true;
              lastPosX.current = opt.clientX;
              lastPosY.current = opt.clientY;
            }
        });
      
        document.addEventListener("mouseup", () => {
        isPanning.current = false;
        });

    }

    const handleMouseDown = (event, canvas) => {
        // return
        // if (points?.length <= 1) {
        const pointer = canvas.getPointer(event.e);
        let startPoint = new fabric.Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 5,
            fill: 'red',
            selectable: false,
            originX: 'center',
            originY: 'center',
            name: 'point'
        });
        canvas.add(startPoint);
        points.push({ x: startPoint.left, y: startPoint.top })
        isDrawing = true
        // }
        if (points.length >= 2) {
            isDrawing = false
            let linePoints = [{ x: points[0].x, y: points[0].y }, { x: points[1].x, y: points[1].y }];
            const endPoint = new fabric.Line(linePoints, {
                stroke: 'red',
                strokeWidth: 1,
                selectable: false,
                name: 'line'
            });
            const distance = calculateDistance(points[0], points[1])
            const length = ((distance * 0.5) / 100) * 10;
            // removeFabricObjectsByName(canvas, 'line_distance')
            // showLength(points, canvas)
            setSelImageOrSvgValues({ points_distance: length.toFixed(2) })
            setSelImageOrSvgValues(prev => ({ ...prev, points_distance: length.toFixed(2) }))
            canvas.add(endPoint);
            canvas.renderAll();
            if (points.length === 3) {
                points = []
                removeFabricObjectsByName(canvas, 'line')
                removeFabricObjectsByName(canvas, 'point')
                removeFabricObjectsByName(canvas, 'line_distance')
                setSelImageOrSvgValues(prev => ({ ...prev, points_distance: null, }))

            }
        }

    }

    const handleMouseMove = (event, canvas) => {
        if (isDrawing) {
            removeFabricObjectsByName(canvas, 'line')
            const pointer = canvas.getPointer(event.e);
            const endPoint = new fabric.Line([points[points.length - 1].x, points[points.length - 1].y, pointer.x, pointer.y], {
                stroke: 'red',
                strokeWidth: 1,
                selectable: false,
                name: 'line'
            });

            canvas.add(endPoint);
            canvas.renderAll();
        };

        // if (isPanning.current) {
        //     const e = event.e;
        //     lastPosX.current = e.clientX;
        //     lastPosY.current = e.clientY;
        // }
    }

    const handleMouseWheel = (options, canvas) => {
        var delta = options.e.deltaY;
        var pointer = canvas.getPointer(options.e);

        var zoom = canvas.getZoom();
        if (delta > 0) {
            zoom /= 1.1;
        } else {
            zoom *= 1.1;
        }
        if (zoom > 10) zoom = 10;
        if (zoom < 0.1) zoom = 0.1;
        canvas.zoomToPoint(
            { x: options.e.offsetX, y: options.e.offsetY },
            zoom
        );
        var canvasCenter = {
            x: canvas.width / 2,
            y: canvas.height / 2
        };
        // canvas.current.zoomToPoint(
        //   { x: canvasCenter.x, y: canvasCenter.y },
        //   zoom
        // );
        canvas.renderAll();
        // adjustContainerSize(zoom);
    }

    const adjustContainerSize = (scaleFactor) => {
        if (refImageDivRef.current) {
            refImageDivRef.current.style.width = `${scaleFactor * 100}%`;
            // refImageDivRef.current.style.height = `${scaleFactor * 100}%`;
        }
    };

    const removeFabricObjectsByName = (canvas, name) => {
        canvas?.forEachObject(function (obj) {
            if (obj.name == name) {
                canvas.remove(obj);
            }
        });
    };

    const showLength = (point, canvas) => {
        let length = 0;
        let center = {};
        for (let i = 1; i < point.length; i++) {
            const point1 = point[i - 1];
            const point2 = point[i];
            const distance = calculateDistance(point1, point2);
            center = {
                x: (point1.x + point2.x) / 2,
                y: (point1.y + point2.y) / 2
            };
            length = ((distance * 0.5) / 100) * 10;
        }

        const angle = Math.atan2(
            point[point.length - 1]?.y - point[0]?.y,
            point[point.length - 1]?.x - point[0]?.x
        );
        const textLeft = center.x - (length / 2) * Math.cos(angle);
        const textTop = center.y - (length / 2) * Math.sin(angle);

        const text = new fabric.Text(`${length.toFixed(2)} m`, {
            left: textLeft,
            top: textTop - 4,
            selectable: false,
            fontSize: 12,
            fontFamily: "Arial",
            fill: "black",
            originX: "center",
            originY: "center",
            fontWeight: 700,
            name: 'line_distance'
        });
        canvas.add(text);
    }

    const ZoomInOut = (canvas, direction) => () => {
        let zoom = canvas.getZoom();
        if (direction === 'in') {
            zoom *= 1.1;
            if (zoom > 10) zoom = 10;
        } else if (direction === 'out') {
            zoom /= 1.1;
            if (zoom < 0.1) zoom = 0.1;
        }
        centerCanvas(canvas, zoom);
    };

    const centerCanvas = (canvas, zoom) => {
        const backgroundImage = canvas.backgroundImage;
        if (backgroundImage) {
            const imgCenter = {
                x: (backgroundImage.left + backgroundImage.width * backgroundImage.scaleX) / 2,
                y: (backgroundImage.top + backgroundImage.height * backgroundImage.scaleY) / 2,
            };
            canvas.zoomToPoint(imgCenter, zoom);
        } else {
            const canvasCenter = {
                x: canvas.width / 2,
                y: canvas.height / 2,
            };
            canvas.zoomToPoint(canvasCenter, zoom);
        }
        canvas.renderAll();
    };


    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            // size="lg"
            style={{ maxWidth: '700px', zIndex: "999999 !important" }}
            centered
        >
            <ModalBody className=" ">
                <h5 className="f-w-600 mb-4" style={{ fontSize: "18px" }}>
                    {values?.imageType === 'import-svg' ? 'Scale Import' : 'Scale Reference Image'}
                </h5>
                <Row className="">
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className='ref-image-div'  ref={refImageDivRef}>
                            {/* <img src={values?.imageScale} alt="scale" /> */}
                            <canvas ref={referenceImgRef} id='referenceCanvas' ></canvas>

                        </div>
                        <div className='zoom-control-div'>
                            <FaPlus className='zoom-icons' onClick={ZoomInOut(canvas, 'in')} />
                            <hr className='horizontal-line' />
                            <FaMinus className='zoom-icons' onClick={ZoomInOut(canvas, 'out')} />
                        </div>
                    </Col>
                </Row>
                <Formik
                    initialValues={{ real_world_distance: '', points_distance: '', ...values, ...selImageOrSvgValues }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, setFieldError) => {
                        handleScaleSubmit(values?.imageType, selImageOrSvgValues)
                    }
                    }
                    enableReinitialize
                >
                    {({
                        errors,
                        values,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        setFieldError
                    }) => (
                        <form
                            className="av-tooltip tooltip-label-bottom formGroups"
                            onSubmit={(e) => {
                                // if (Object.keys(errors).length > 0) {
                                // let errorMessage = '';
                                // console.log(errors, values)
                                // if (errors.points_distance) {
                                //     errorMessage += `${errors.points_distance}\n`;
                                //     toast.error(errors.points_distance)
                                // }
                                // }
                                handleSubmit(e, setFieldError);
                            }}
                        >
                            <Row className="mt-2">
                                <Col md={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                    <p className='ref-image-text'>Select two points on your floor plan.</p>
                                    <p className='ref-image-text'> Enter the real world distance between the selected points.</p>
                                    {/* <div className='scale-meter-div mt-2' >
                            <p>60</p>
                            <div className='scale-meter'>m</div>
                        </div> */}
                                    <div className="d-flex mt-2 justify-content-center">
                                        <input
                                            type="text"
                                            className="form-control non-focus-input"
                                            style={{ width: '60%' }}
                                            // placeholder="Search..."
                                            value={values.real_world_distance}
                                            onChange={(e) => {
                                                handleChange(e)
                                                setSelImageOrSvgValues(prev => ({ ...prev, real_world_distance: e.target.value, }))
                                            }}
                                        // onKeyDown={handlePrevent}
                                        />
                                        <div
                                            className="input-group-append"
                                            style={{ marginLeft: "-36px" }}
                                        >
                                            <span className="input-group-text scale-meter">m</span>
                                        </div>
                                    </div>

                                    {errors.real_world_distance && touched.real_world_distance ? (
                                        <div className="text-danger mt-1">
                                            {errors.real_world_distance}
                                        </div>
                                    ) : null}
                                </Col>
                            </Row>

                            <div
                                className="form-group text-right "
                                style={{ marginTop: "30px" }}
                            >
                                <Button
                                    color="secondary"
                                    className="btn btnCancel mr-3"
                                    onClick={() => {
                                        toggle()
                                        setSelImageOrSvgValues()
                                        if (values?.imageType !== 'import-svg') {
                                            setSelFloorPlanDtls((prev) => ({ ...prev, plan: null, refImg: '', image: null, show_image: 0 }))
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                                {/* <Button
                                    color="primary"
                                    type="submit"
                                    className="btn btn-primary float-right"
                                // onClick={() => {
                                //     if (selImageOrSvgValues?.points_distance && selImageOrSvgValues?.real_world_distance) {
                                //         handleScaleSubmit(values?.imageType, selImageOrSvgValues)
                                //     }
                                // }}
                                >
                                    Save
                                </Button> */}
                                <Button color="primary" type="submit" className="btn btn-primary float-right" disabled={loading} >
                                    {loading ? (
                                        <>
                                            <p style={{ opacity: '0', position: 'relative' }}>Save</p>
                                            <Spinner
                                                className="ml-2 spinner-style"
                                                color="light"
                                            />
                                        </>
                                    ) : 'Save'}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </ModalBody>

        </Modal>
    )
}
export default ReferenceImageModal;