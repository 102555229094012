import { bringFabricObjectsToFrontByName, removeFabricObjectsBId, removeFabricObjectsByName } from "../bringFabricObjects";

const highligthNodes = (canvas, projectSettings, shortestPath) => {
    canvas.current.forEachObject(function (obj) {
        if (obj.name === "node") {
            if (shortestPath.includes(obj.id)) {
                if (
                    !shortestPath.includes(obj.id) ||
                    (shortestPath[0] !== obj.id &&
                        shortestPath[shortestPath.length - 1] !== obj.id)
                ) {
                    let newCircle = new fabric.Circle({
                        ...obj,
                        // fill: obj?.fill == 'rgba(0,255,0,0.5)' ? 'rgba(0,255,0,0.5)' : projectSettings?.navigation_color ?? "red"
                        fill: projectSettings?.navigation_color ?? "red"
                    });
                    canvas.current.add(newCircle);
                    newCircle.bringToFront();
                }
                canvas.current.renderAll();
            } else {
                let newCircle = new fabric.Circle({
                    ...obj,
                    fill: obj?.subpath ? 'rgba(0,255,0,0.5)' : "rgba(0,0,255,0.5)"
                });
                removeFabricObjectsBId(canvas, obj.id);
                canvas.current.remove(obj);
                canvas.current.add(newCircle);
                canvas.current.renderAll();
            }
        }
    });
    bringFabricObjectsToFrontByName(canvas, "product");
    bringFabricObjectsToFrontByName(canvas, "location");
    bringFabricObjectsToFrontByName(canvas, "beacon");
    bringFabricObjectsToFrontByName(canvas, "amenity");
    bringFabricObjectsToFrontByName(canvas, "safety");
    bringFabricObjectsToFrontByName(canvas, "vertical");
};

export default highligthNodes;