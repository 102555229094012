import React, { useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row, Col, Label, Button, Table, CardBody, Card
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import "../pages/project/project.css";
import { getRequest, getRequestForDownload, postRequest, deleteRequest } from '../hooks/axiosClient';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocationSvgSmall, ProductSvgSmall } from "../pages/buildProject/CustomSvg";
import { encode, getCurrentUser } from "../helpers/utils";
import { FiSearch } from "react-icons/fi";
import noDataImg from "../assets/img/noData.png";
import PaymentForm from './stripe/payment';
import MoveOrCopy from "./modal/moveOrCopyModal";


const AccordionItem = ({ panel, getProjectlist, index, getProjectById }) => {
  const [isOpen, setIsOpen] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [planDetails, setPlanDetails] = useState();
  const [stripeModal, setStripeModal] = useState(false);
  const toggleStripe = () => setStripeModal(!stripeModal);
  const [fromStatus, setFromStatus] = useState(false);
  const [moveOrCopyModal, setMoveOrCopyModal] = useState(false);
  const toggleMoveOrCopy = () => setMoveOrCopyModal(!moveOrCopyModal);
  const [moveOrCopy, setMoveOrCopy] = useState();
  const [rowDetails, setRowDetails] = useState();




  const styleElement = document.createElement("style");
  styleElement.innerHTML = `

.free{
  color:#6a6d73;
  background-color:#dddddd
}
.pro{
  color:#f9b74c;
  background-color:#fde9ca
}
.active{
  color:#6daa76;
}
.inactive{
  color:#D93025;
}
.publish{
  padding: 4px 20px;
  font-size:1rem;
  border-radius: 6px;
  color: #69a472;
  font-weight: 400;
  background-color: #dff7e3;

}
.pending{
  padding: 4px 20px;
  font-size:1rem;
  border-radius: 6px;
  color: #6a6d73;
  font-weight: 400;
  background-color: #f5f6f7;

}
.actionIcons{
  padding: "3px 6px 4px 6px",
  backgroundColor:"#dff1fa",
  borderRadius:"6px",
  
}
.dropdown-toggle {
  background-color: transparent; /* Remove background color */
  border: none; /* Remove border */
  box-shadow: none; /* Remove box shadow */
  color: inherit; /* Inherit text color */
  padding: 0; /* Remove padding */
}
.dropdown-toggle::after {
  display: none;
}
 .dropdown-menu {
  font-size: 0.875rem !important;
  //  width: auto !important;
  --bs-dropdown-min-width: 5rem !important;
  inset: 0px 0px auto auto !important;
  // box-shadow:0px 0px 15px 1px #ccc !important;
  // border: none !important;

}
.drpdown{
   width: auto !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #f3f8fa !important
}
.anticon svg {
  margin-top:0px !important
}
.round{
  height:10px;
  width:10px;
  border-radius:50px;
}

.customer-email{
  padding: 4px 20px;
  font-size: 0.875rem;
  border-radius: 6px;
  color: #26a3db;
  font-weight: 500;
  background-color: #dff1fa;
}
`;

  document.head.appendChild(styleElement);

  const role_id = getCurrentUser()?.user?.role_id;

  const statusChange = async (id, values, isAccepted, ip) => {
    let data = {}
    if (values) {
      data.is_accepted = isAccepted == true ? 1 : 0,
        data.ip_address = ip
    }
    try {
      const response = await postRequest(`project/${id}/status`);
      const data = response.response?.data ?? [];
      console.log(response);
      if (response?.type === 2) {
        swal({
          text: response?.errormessage,
          icon: "error",
        })
      } else {
        swal({
          text: data?.message,
          icon: "success",
        })
        getProjectlist();
      }
      if (values) {
        toggleStripe();
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleDuplicate = async (id) => {
    let data = {
      project_id: id,
    }
    try {
      const response = await postRequest(`project-clone`, data);
      getProjectlist();
    } catch (error) {
      console.log(error);
    }
  }

  const StatusClick = (row) => {
    swal({
      title: "Are you sure",
      text: "You want to change status?",
      icon: "warning",
      buttons: [
        {
          text: "No",
          value: "No",
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
        {
          text: "Yes",
          value: "Yes",
          visible: true,
          className: "btn-success",
          closeModal: true,
        },
      ],
    })
      .then((value) => {
        switch (value) {
          case "Yes":

            if (row?.status == 0 && ((row?.published_date != null && row?.recurring_date == null) || (row?.is_copy == 1 && row?.published_date == null && row?.recurring_date == null && row?.is_basic == 1))) {
              checkPackageDetails(row?.enc_id, 'status');
            } else {
              statusChange(row?.enc_id)
            }
            break;
          default:
            break;
        }
      });
  }

  const toggleDropdown = (index, panel) => {
    const updatedOpenStates = [...isOpen];
    updatedOpenStates[index] = !updatedOpenStates[index];
    if (role_id != 1) {
      if (panel.inactivated_by == 1) {
        toast.error('Your project is inactive. Please contact the administrator.')
      } else {
        setIsOpen(updatedOpenStates);
      }
    } else {
      setIsOpen(updatedOpenStates);
    }
  };

  const EditClick = (panel) => {
    if (window.innerWidth > 1100) {
      navigate(`/view-floor/${encode(panel?.enc_id)}`)
    } else {
      toast.warning('These modules do not support the mobile version. Please switch to  laptops, or PCs to access the advantages',
        { autoClose: 5000 }
      )
    }
  }

  const generateQrcode = async (row, type) => {
    setLoading(true);
    try {
      const response = await getRequestForDownload(`generate-qr/${row?.enc_id}/${type}`);
      const dataRes = response.data;
      downloaQr(dataRes, row)

    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Please publish the project to generate QR Code Beacon.")
      }
    } finally {

      setTimeout(() => {
        setLoading(false)
      }, 3000);
    }
  }

  const downloaQr = (dataRes, row) => {
    const blob = new Blob([dataRes], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const pdfName = row?.beacon_name
    link.setAttribute("download", `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    toast.success('QR Code beacon poster generated successfully.');
  }

  const deleteClick = (row) => {
    swal({
      title: "Are you sure you want to delete?",
      text: "This action is permanent and cannot be undone.",
      icon: "warning",
      buttons: [
        {
          text: "No",
          value: "No",
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
        {
          text: "Yes",
          value: "Yes",
          visible: true,
          className: "btn-success",
          closeModal: true,
        },
      ],
    })
      .then((value) => {
        switch (value) {
          case "Yes":
            deleteProject(row?.enc_id)
            break;
          default:
            break;
        }
      });
  }

  const deleteProject = async (id) => {
    try {
      const response = await deleteRequest(`project/${id}`);
      const data = response.data ?? [];
      toast.success(data?.message);
      getProjectlist()
    } catch (error) {
      console.log(error);
    }
  }

  const publishClick = (row) => {
    if (row?.logo == null) {
      toast.error('Please upload the project logo for publishing.')
    } else if (!row?.error_reporting_email) {
      toast.error("Please enter the error report recipient's email address to publish the project.");
    } else {
      swal({
        title: "Are you sure you want to publish?",
        text: "Publishing will overwrite old data, making it irreversible.",
        icon: "warning",
        buttons: [
          {
            text: "No",
            value: "No",
            visible: true,
            className: "btn-danger",
            closeModal: true,
          },
          {
            text: "Yes",
            value: "Yes",
            visible: true,
            className: "btn-success",
            closeModal: true,
          },
        ],
      })
        .then((value) => {
          switch (value) {
            case "Yes":
              handlePublish(row?.enc_id);
              break;
            default:
              break;
          }
        });
    }
  }

  const checkPackageDetails = async (id, from) => {
    try {
      const reqUrl = `check-package/${id}`;
      const response = await getRequest(reqUrl);
      const data = response?.data ?? [];
      setPlanDetails(data);
      if (from == 'status') {
        setFromStatus(true);
        setStripeModal(true);
      } else {
        setFromStatus(false);
        if (data?.plan?.basic_expired == 1 || data?.plan?.additional_expired == 1 || data?.plan?.additional_count == 1) {
          setStripeModal(true);
        } else {
          handlePublish(id);
        }
      }
    } catch (error) {
    }
  }

  const handlePublish = async (rowid, values, isAccepted, ip) => {
    let data = {
      id: Number(rowid),
    };
    // if (values) {
    //   data.free_expired = values?.plan?.free_expired,
    //     data.basic_expired = values?.plan?.basic_expired,
    //     data.additional_expired = values?.plan?.additional_expired,
    //     data.additional_count = values?.plan?.additional_count,
    //     data.basic_expired = values?.plan?.basic_expired,
    //     data.is_accepted = isAccepted == true ? 1 : 0,
    //     data.ip_address = ip
    // }
    try {
      const reqUrl = `publish`;
      const response = await postRequest(reqUrl, data);
      // handleEnableDisable();
      const result = response?.response?.data ?? [];
      if (response?.type === 1) {
        toast.success(result?.message);
        getProjectlist()
      } else {
        toast.error(response?.errormessage);
      }
    } catch (error) {
    }
    return

  };
  const discardClick = (rowId) => {
    swal({
      title: "Are you sure you want to discard?",
      text: " Once discarded, the latest published details will be restored.",
      icon: "warning",
      buttons: [
        {
          text: "No",
          value: "No",
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
        {
          text: "Yes",
          value: "Yes",
          visible: true,
          className: "btn-success",
          closeModal: true,
        },
      ],
    })
      .then((value) => {
        switch (value) {
          case "Yes":
            handleDiscard(rowId)
            break;
          default:
            break;
        }
      });
  }

  const revertPackage = async (rowId) => {
    try {
      const reqUrl = `revert-package/${rowId}`;
      const response = await getRequest(reqUrl);
    } catch (error) {
    }
  }

  const handleDiscard = async (rowId) => {
    let data = {
      id: Number(rowId),
    }
    try {
      const reqUrl = `discard`;
      const response = await postRequest(reqUrl, data);
      // handleEnableDisable();
      const result = response?.response?.data ?? [];
      toast.success(result?.message);
      revertPackage(rowId);
      setTimeout(() => {
        getProjectlist()
      }, 500);
    } catch (error) {
    }
  }

  const handleMoveOrCopy = (row, type) => {
    /* type 1 = Move To
    type 2 = Copy To */
    setMoveOrCopyModal(true)
    setMoveOrCopy(type);
    setRowDetails(row);
    console.log('herehere')

  }

  const renderPanelHeader = (panel, index) => (
    <>
      <Row>
        <Col md={6} className="mb-2">
          <div style={{ color: "#1D1D1B" }} className="d-flex align-items-center" >
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "16px",
              height: "16px",
              borderRadius: "4px",
              marginRight: "6px"
            }}>
            </div>
            <span className="round mr-2" style={{ backgroundColor: panel?.status == 1 ? '#98d12c' : 'red' }}></span>
            <h5 className="f-w-600 f-size" >{panel?.project_name}</h5>
          </div>
        </Col>
        <Col md={6} className="mb-2">
          <div className="d-flex justify-content-end align-items-center">
            <span className={panel?.is_published == 1 ? "publish" : 'pending'}  >
              {panel?.is_published == 1 ? 'Published' : 'Pending Changes'}
            </span>
            <span
              style={{ fontWeight: '600', marginLeft: "15px", fontSize: '0.875rem', padding: '4px 20px ', borderRadius: "6px" }}
              className={`mr-3 ${panel.is_basic == 1
                ? " pro "
                : "free"
                }`}
            >
              {panel.is_basic == 1 ? "PRO" : "FREE"}
            </span>
            <Dropdown isOpen={isOpen[index]} toggle={() => toggleDropdown(index, panel)} className="dropdown-toggle">
              <DropdownToggle caret>
                <span className='menuIcon' style={{ padding: "13px 13px ", backgroundColor: "#dff1fa", borderRadius: "6px", color: "#26A3DB" }}>
                  <MoreOutlined style={{ fontSize: "16px", marginTop: '0px !important' }} />
                </span>
              </DropdownToggle>
              <DropdownMenu className="drpdown">
                <DropdownItem className={`d-flex align-items-center mr-3 `} style={{ color: panel.status === 1 ? "#E13025" : "#98d12c" }} onClick={() => StatusClick(panel)} >{panel.status == 1 ? "Deactivate " : "Activate"}</DropdownItem>
                <>
                  <hr></hr>
                  <DropdownItem onClick={() => EditClick(panel)} className="d-flex align-items-center" >Edit</DropdownItem>
                  <hr></hr>
                  <DropdownItem onClick={() => publishClick(panel)} className="d-flex align-items-center" disabled={(panel?.publish == 0 || panel?.status == 0)} >Publish</DropdownItem>
                  <DropdownItem onClick={() => discardClick(panel?.enc_id)} className="d-flex align-items-center" disabled={panel?.discard == 0} >Discard Changes</DropdownItem>
                  <hr></hr>
                  <DropdownItem onClick={() => getProjectById(panel?.enc_id)} className="d-flex align-items-center" >Rename</DropdownItem>
                  {(role_id != 1 && panel.is_basic != 0) &&
                    <DropdownItem onClick={() => handleDuplicate(panel?.enc_id)} className="d-flex align-items-center" disabled={panel.is_basic == 0} >Duplicate</DropdownItem>
                  }
                  <hr></hr>
                  <DropdownItem className="d-flex align-items-center" onClick={() => setModal(true)} disabled={(panel?.status == 0)}>Generate QR Code Beacon Poster</DropdownItem>
                  <hr></hr>
                  <DropdownItem className="d-flex align-items-center" onClick={() => deleteClick(panel)}>Delete</DropdownItem>
                  {(role_id == 1 && panel.is_basic != 0) &&
                    <>
                      <hr></hr>

                      <DropdownItem style={{ color: '#9C27B0' }} className="d-flex align-items-center" onClick={() => handleMoveOrCopy(panel, 1)}>Move To</DropdownItem>
                      <DropdownItem style={{ color: '#9C27B0' }} className="d-flex align-items-center" onClick={() => handleMoveOrCopy(panel, 2)}>Copy To</DropdownItem>
                    </>
                  }
                </>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row style={{ fontSize: "1rem" }}>
        <Col md={6} style={{ paddingLeft: '33px' }}>
          <div className="mt-2 d-flex">
            <span style={{ cursor: "pointer", display: 'flex' }}>
              <LocationSvgSmall color={panel?.location_color} /> <span className="ml-1"> {panel?.pin_details?.used_loc}/{panel?.pin_details ? (panel?.pin_details?.free_loc) + (panel?.pin_details?.basic_loc) + (panel?.pin_details?.add_loc) : ''}</span>
            </span>
            <span style={{ cursor: "pointer", marginLeft: "30px", display: 'flex' }}>
              <ProductSvgSmall color={panel?.product_color} /> <span className="ml-1"> {panel?.pin_details?.used_prod}/{panel?.pin_details ? (panel?.pin_details?.free_prod) + (panel?.pin_details?.basic_prod) + (panel?.pin_details?.add_prod) : ''}</span>
            </span>
          </div>
        </Col>
        <Col md={6}>
          <span className="float-right " style={{ alignItems: 'center', marginTop: '10px' }}>
            {role_id == 1 &&
              <span className={"customer-email "}  >
                {panel?.email}
              </span>
            }
          </span>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle2} style={{ zIndex: '999999 !important', maxWidth: '700px' }} centered>
        <ModalHeader toggle={toggle2} style={{ padding: '33px 32px 0px 32px' }}>
          Generate QR Code Beacon Poster
        </ModalHeader>
        <ModalBody className="modalbody-padding" >
          <Row >
            <Col md={12}>
              <div className="mb-2">
                <Label style={{ color: '#6a6d73', fontSize: '0.875rem', fontWeight: 'bold', marginTop: '11.48px' }}>{panel?.project_name}</Label>
              </div>
            </Col>
          </Row>
          <Row >
            <Col md={12}>
              <div className="table-responsive">
                <Table className="custom-table">
                  <thead>
                    <tr>
                      <th className='theadStyle'>QR Code Beacon</th>
                      <th className='theadStyle'>Print</th>
                      <th className='theadStyle'>Web</th>
                    </tr>
                  </thead>
                  <tbody>
                    {panel?.beacon_data?.length > 0 ? (
                      panel?.beacon_data?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.beacon_name}</td>
                          <td className='d-flex'>
                            <button className="btn btn-sm buttoninfo mr-2 mb-1" onClick={() => generateQrcode(item, 1)}>A4</button>{" "}
                            <button className="btn btn-sm buttoninfo mb-1" onClick={() => generateQrcode(item, 2)}>A3</button>
                          </td>
                          <td>
                            <button className="btn btn-sm buttoninfo mb-1" onClick={() => generateQrcode(item, 3)}>FHD</button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col md={12}>
              <Button color="secondary" className="btn btnCancel float-right" onClick={toggle2}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {loading &&
        <Modal isOpen={true} size="sm" className="loading-modal" style={{ zIndex: '9999999 !important', maxWidth: '200px', backgroundColor: 'transparent', justifyContent: 'center' }} centered>
          <ModalBody >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div class="folder">
                <span class="folder-tab"></span>
                <div class="folder-icn">
                  <div class="downloading">
                    <span class="custom-arrow"></span>
                  </div>
                  <div class="bar-downld"></div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      <MoveOrCopy
        modal={moveOrCopyModal}
        toggle={toggleMoveOrCopy}
        type={moveOrCopy}
        rowDetails={rowDetails}
        getProjectlist={getProjectlist}

      />
    </>
  );

  return (
    <div className="" style={customPanelStyle}>
      <div className="" >
        <div >
          <div>{renderPanelHeader(panel, index)}</div>
        </div>
      </div>
      <PaymentForm
        toggleStripe={toggleStripe}
        stripeModal={stripeModal}
        planDetails={planDetails}
        project_id={panel?.enc_id}
        fromStatus={fromStatus}
        from='project'
        handlePublish={handlePublish}
        statusChange={statusChange}
      />
    </div>
  );
};


const customPanelStyle = {
  background: "#ffff",
  borderRadius: 6,
  marginTop: '18.71px',
  border: 0,
  padding: "15px",
};

const Accordion = ({ projectList, getProjectlist, getProjectById }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tempProject, setTempProject] = useState([]);
  const [noData, setNodata] = useState(false);

  useEffect(() => {
    setTempProject(projectList);
  }, [projectList])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const currentValue = e.target.value;
    if (currentValue) {
      const value = e.target.value.toLowerCase();
      const colArray = ['project_name', 'email', 'customer_name',];
      const count = colArray.length;
      if (count > 0) {
        const filterData = projectList.filter((item) => {
          let returnvalue = false;
          for (let i = 0; i < count; i += 1) {
            if (
              (item[colArray[i]] &&
                item[colArray[i]].toString().toLowerCase().indexOf(value) !==
                -1) ||
              !value
            ) {
              returnvalue = true;
              return true;
            }
          }
          return returnvalue;
        });
        if (filterData?.length == 0) {
          setNodata(true)
        } else {
          setNodata(false)
        }
        setTempProject(filterData);
      }
    } else {
      setTempProject(projectList);
      setNodata(false)
    }
  };

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={4} xl={2} xxl={2}>
          <div className="d-flex">
            <input
              type="text"
              value={searchTerm}
              className="form-control"
              placeholder="Search..."
              onChange={(e) => handleSearchChange(e)}
            />
            <div
              className="input-group-append"
              style={{ marginLeft: "-36px" }}
            >
              <span
                className="input-group-text"
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: '4px'
                }}
              >
                <FiSearch className="iconStyle" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {(!noData) ? (
        tempProject.map((panel, index) => (
          <>
            <AccordionItem key={panel?.enc_id} panel={panel} getProjectlist={getProjectlist} index={index} getProjectById={getProjectById} />
          </>
        ))
      )
        :
        (
          <div className="row mt-3">
            <div className="col-sm-12">
              <Card>
                <CardBody>
                  <div className='text-center'>
                    <div className="d-flex align-items-center justify-content-center mb-2 pt-3">
                      <div className="d-flex justify-content-center">
                        <img src={noDataImg} style={{ width: "35%" }}></img>
                      </div>
                    </div>
                    <div className='text-center'>
                      <p style={{ fontSize: '14px', fontWeight: 500 }}>No data found!</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Accordion;
