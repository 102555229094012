import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'

const ProdSpecItem = ({ spec, index, setSpecifications, specifications, setFieldValue, name,setIsDirty }) => {

    const [isDeleted, setIsDeleted] = useState(false);

    const onChangeInput = (e, type) => {
        let tempSpec = [...specifications]
        tempSpec[index] = { ...tempSpec[index], [type]: e.target.value };
        setSpecifications([...tempSpec]);
        setFieldValue(name[index], tempSpec);
        setIsDirty(true);
    }

    const onDelete = () => {

        swal({
            title: "Are you sure you want to delete?",
            text: "This action is permanent and cannot be undone.",
            icon: "warning",
            buttons: [
                {
                    text: "No",
                    value: "No",
                    visible: true,
                    className: "btn-danger",
                    closeModal: true,
                },
                {
                    text: "Yes",
                    value: "Yes",
                    visible: true,
                    className: "btn-success",
                    closeModal: true,
                },
            ],
        })
            .then((value) => {
                switch (value) {
                    case "Yes":
                        setSpecifications(prevSpecs => prevSpecs.filter((_, i) => i !== index));
                        setTimeout(() => {
                            setFieldValue(name, specifications);
                        }, 500);
                        setIsDirty(true);

                        break;
                    default:
                        break;
                }
            });
        return
    }

    return (
        <div className='prod-spec-item' >
            <input className='form-control' placeholder="Please Type Name" value={spec.label || ''} onChange={(e) => onChangeInput(e, 'label')} />
            <input className='form-control' placeholder="Please Type Value" value={spec.value || ''} onChange={(e) => onChangeInput(e, 'value')} />
            <div className=' p-1 rounded-circle' onClick={() => { onDelete() }} style={{ backgroundColor: '#E5E5E5', cursor: 'pointer' }} >
                <IoMdClose  fontSize={10} />
            </div>
        </div>
    )
}

export default ProdSpecItem

